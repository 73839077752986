<template>
  <a-card>
    <div slot="title">基本信息</div>
    <a-form-model v-bind="$formItemLayout4DetailSm">
      <a-form-model-item label="封面：">
        <a-avatar :size="70" shape="square" :src="goodsSource.coverUrl"></a-avatar>
      </a-form-model-item>
      <a-form-model-item label="购买须知：">
        <span>{{ goodsSource.purchaseInstructions }}</span>
      </a-form-model-item>
      <a-form-model-item label="是否在APP显示：">
        <span>{{ goodsSource.appHide ? '否' : '是' }}</span>
      </a-form-model-item>
      <a-form-model-item label="分类：">
        <span>{{ goodsSource.service.type | ServiceManagerTypeText }}</span>
      </a-form-model-item>
      <a-form-model-item label="介绍：">
        <span>{{ goodsSource.service.introduction }}</span>
      </a-form-model-item>
      <a-form-model-item label="服务介绍：">
        <span>{{ goodsSource.service.characteristic }}</span>
      </a-form-model-item>
      <a-form-model-item label="视频介绍：">
        <a :href="goodsSource.service.videoUrl" class="content-label" target="blank_"
          ><a-icon type="file" style="margin-right:8px" />{{ goodsSource.service.videoUrl }}</a
        >
      </a-form-model-item>
    </a-form-model>
    <a-row v-if="goodsSource.hasSpecification">
      <a-col :xs="{ span: 22, offset: 1 }">
        <a-table bordered rowKey="id" :data-source="goodsSource.goodsSpecificationsVOSet" :pagination="false">
          <a-table-column key="description" title="规格名称" align="center" data-index="description" width="120px" />
          <a-table-column key="value" title="数量" align="center" data-index="value" width="120px" />
          <a-table-column key="originPrice" title="原始售价" align="center" data-index="originPrice" width="120px" />
          <a-table-column key="discount" title="折扣" align="center" width="120px">
            <template slot-scope="record">
              <span>{{ record.discount }}%</span>
            </template>
          </a-table-column>
          <a-table-column key="sellPrice" title="实际售价" align="center" data-index="sellPrice" width="120px" />
        </a-table>
      </a-col>
    </a-row>
    <a-form-model v-else v-bind="$formItemLayout4DetailSm">
      <a-form-model-item label="原始售价：">
        <span>￥{{ goodsSource.discount }}</span>
      </a-form-model-item>
      <a-form-model-item label="折扣：">
        <span>{{ goodsSource.sellPrice }}%</span>
      </a-form-model-item>
      <a-form-model-item label="实际售价：">
        <span>￥{{ goodsSource.sellPrice }}</span>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
import * as goodsserviceApi from '@/api/goodsservice'
export default {
  name: 'GoodsManagementServiceIndividualDetail',
  data() {
    return {
      goodsSource: {
        appHide: undefined,
        coverUrl: '',
        name: '',
        discount: '',
        originPrice: '',
        sellPrice: '',
        goodsSpecificationsVOSet: [
          {
            description: '',
            discount: '',
            id: '',
            originPrice: '',
            sellPrice: '',
            value: ''
          }
        ],
        hasSpecification: undefined,
        purchaseInstructions: '',
        service: {
          characteristic: '',
          id: '',
          type: '',
          introduction: '',
          name: '',
          videoUrl: ''
        }
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id }
        }
      } = this
      goodsserviceApi.individualDetail(id).then(res => {
        this.goodsSource = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content-label {
  white-space: normal;
  word-break: break-all;
}
</style>
